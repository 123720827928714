import type { DpListener } from '@/core/events/DpListener';
import { DpEventBus } from '@/core/events/DpEventBus';
import { inject, singleton } from 'tsyringe';
import { ErrorLoggerToken } from '../../di/tokens';
import type { ErrorLogger } from '../ErrorLogger';
import type { ClientStateChanged } from '../events/ClientStateChanged';
import { RealTimeConnectionError } from './RealTimeConnectionError';
import { RealTimeConnectionRestored } from '../events/RealTimeConnectionRestored';

@singleton()
export class ConnectionFailureListener implements DpListener {
  private hasFailedConnection = false;

  constructor(
    @inject(ErrorLoggerToken)
    private readonly errorLogger: ErrorLogger,
    private readonly eventBus: DpEventBus,
  ) {}

  handle({ state }: ClientStateChanged): void {
    if (state.hasConnectionIssues) {
      this.hasFailedConnection = true;

      this.errorLogger.logInfoWithContext(new RealTimeConnectionError('Connection issues'), {
        connectionState: {
          previous: state.previousValue,
          current: state.value,
        },
      });

      return;
    }

    if (this.hasFailedConnection && state.isConnected) {
      this.eventBus.publish(new RealTimeConnectionRestored());

      this.hasFailedConnection = false;
    }
  }
}
