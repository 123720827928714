import { injectable } from 'tsyringe';
import type { ChannelConfiguration } from './ChannelConfiguration';
import { ConfigurationRepository } from './ConfigurationRepository';

@injectable()
export class ChannelConfigurationReader {
  constructor(private readonly configurationRepository: ConfigurationRepository) {}

  get(): ChannelConfiguration | null {
    return this.configurationRepository.get();
  }
}
