import { phoneApi } from '@/api/phone-api';
import { getSettingsActions, getSettingsMutations } from '@/app/settings/store-factory';
import { prop, sortByWeight } from '@/utils/functions';
import { createLocalStorageBucket } from '@/utils/cache';
import { isNotFound, isUnauthorized } from '@/api/phone-api-errors';
import { pushToFlutter } from '@/utils/mobile';
import { facilityRepository, uploadMediaFileRepository } from '@/api/repositories';
import { Permission } from '@/api/models/Permission';
import { facilityMapper } from '@/api/mappers';
import { MUTATION_ENTITIES_UPDATE } from '@dp-vue/entities';
import { channelConfigurationUpdater } from '@/common/real-time-communication/public/api';
import { subscriptionFetcher } from '@/common/authorization/public/api';

const storedFacilityId = createLocalStorageBucket('facility')('id');

const getEmptyFacility = () => ({
  _acl: 'VIEW',
  timezone: 'UTC',
  name: '',
  status: '',
  channels: {
    regulatory: {
      required: false,
      satisfied: false,
    },
  },
  reception: {},
  results: [],
  phones: [],
  forms: [],
  permissions: [],
  integrations: [],
  customer: {
    activation_date: null,
  },
  docplanner_integration: {},
  anonymized: null,
});

const state = {
  facilityId: null,
  facilities: [],
  facility: getEmptyFacility(),
  roles: [],
  uploading: false,
};

const mutations = {
  ...getSettingsMutations('RESULTS', ['results']),
  ...getSettingsMutations('PERMISSIONS', ['permissions']),
  ...getSettingsMutations('PHONES', ['phones']),
  ...getSettingsMutations('FORMS', ['forms']),

  SET_FACILITIES(state, data) {
    state.facilities = data;
  },

  SET_FACILITY(state, data) {
    state.facility = data;
    state.facilityId = data.id;
  },

  SET_ROLES(state, payload) {
    state.roles = payload;
  },

  SET_USER_UPLOADING(state, payload) {
    state.uploading = payload;
  },
};

const uniqueUser = ({ id }, index, users) => index === users.findIndex(user => user.id === id);

const getters = {
  getUserFacility: state => state.facility,
  getAllFacilities: state => state.facilities,
  hasMultipleFacilities: (_, getters) => getters.getAllFacilities?.length > 1,
  hasNoFacility: (_, getters) => getters.getAllFacilities.length === 0,
  getRoles: state => state.roles,
  hasRole: state => roleName => state.roles.includes(roleName),
  hasAnyOfRoles: state => roleNames => state.roles.some(role => roleNames.includes(role)),
  getFacilityTimeZone: state => state.facility.timezone,
  getUserFacilityID: state => state.facilityId,
  getFacilityResults: state => [...state.facility.results].sort(sortByWeight),
  getFacilityForms: state => [...state.facility.forms].sort(sortByWeight) || [],
  getFacilityPersonnel: (_, getters) =>
    getters.getFacilityPermissions.map(permission => permission.user).filter(uniqueUser),
  getFacilityPermissions: state =>
    [...state.facility.permissions].sort(sortByWeight).map(Permission.make),
  needsToSelectPhone: (state, getters, rootState, rootGetters) =>
    rootGetters.getUser.isManager === false &&
    rootGetters['settings/reception/getReceptionPhones'].length > 0 &&
    rootGetters['settings/reception/isSelectedPhone'] === false,
  isAuthenticated: state => state.isAuthenticated,
  isUserUploading: state => state.uploading,
  getFacilityRegulatory: state => state.facility.channels.regulatory,
  isFacilityAnonymized: state => !!state.facility.anonymized,
};

const actions = {
  ...getSettingsActions('RESULTS', 'withResults'),
  ...getSettingsActions('PHONES', 'withPhones'),
  ...getSettingsActions('FORMS', 'withForms'),

  GET_USER_ACTION: async ({ dispatch, rootGetters }) => {
    if (!rootGetters.isUserLoaded) {
      const meResponse = await phoneApi.getMe();

      await dispatch('HANDLE_ME_RESPONSE', meResponse);
    }

    return rootGetters.getUser;
  },

  HANDLE_ME_RESPONSE: async ({ dispatch, commit }, { me, roles, facilities, pusher }) => {
    const getFirstFacilityId = facilitiesList => facilitiesList.map(prop('id'))[0];

    commit('SET_USER', me);
    commit('SET_ROLES', roles);
    commit('SET_FACILITIES', facilities);

    // we need to store key without facility, for cases that CS would not log in to any facility.
    channelConfigurationUpdater().set(pusher.app_key, pusher.cluster, '', '');

    try {
      const facilityId = storedFacilityId.value || getFirstFacilityId(facilities);

      await dispatch('FETCH_AND_SET_FACILITY', { facilityId, pusher });
    } catch (e) {
      if (isUnauthorized(e) || isNotFound(e)) {
        await dispatch('CLEAR_FACILITY_ID_ACTION');

        await dispatch('FETCH_AND_SET_FACILITY', {
          pusher,
          facilityId: getFirstFacilityId(facilities),
        });
      }
    }
  },

  FETCH_AND_SET_FACILITY: async ({ commit, dispatch }, { facilityId, pusher }) => {
    if (!facilityId) {
      return;
    }

    const facility = await facilityRepository.get(facilityId);

    // Configuration needs to be saved before we load facility
    channelConfigurationUpdater().set(
      pusher.app_key,
      pusher.cluster,
      facility.realtimeChannels.common,
      facility.realtimeChannels.pii,
    );

    const { entities } = facilityMapper.normalizeSingle(facility);
    commit(MUTATION_ENTITIES_UPDATE, entities, { root: true });

    await dispatch('SET_FACILITY_ACTION', facility);
    storedFacilityId.set(facility.id);
  },

  CLEAR_FACILITY_ID_ACTION: async ({ commit }) => {
    channelConfigurationUpdater().clear();
    storedFacilityId.clear();
    commit('SET_FACILITY', getEmptyFacility());
  },

  USER_LOGOUT_ACTION: async ({ commit, dispatch }) => {
    channelConfigurationUpdater().clear();
    commit('DESTROY_USER');
    commit('DELETE_ALL_TASKS_RESULTS');
    dispatch('DELETE_ALL_ENTITIES_ACTION');
    commit('SET_FACILITY', getEmptyFacility());
    await subscriptionFetcher().clear();

    pushToFlutter({
      type: 'logout',
    });
  },

  SET_FACILITY_ACTION: async ({ commit, dispatch }, facility) => {
    await dispatch('settings/SET_ACTION', facility);
    commit('SET_FACILITY', facility);
    await subscriptionFetcher().fetch();
  },

  CHANGE_FACILITY_ACTION: async ({ commit, dispatch }, facility) => {
    await dispatch('DELETE_ALL_ENTITIES_ACTION');
    commit('DELETE_ALL_TASKS_RESULTS');
    await subscriptionFetcher().clear();
    channelConfigurationUpdater().update(
      facility.realtimeChannels.common,
      facility.realtimeChannels.pii,
    );

    const { entities } = facilityMapper.normalizeSingle(facility);
    commit(MUTATION_ENTITIES_UPDATE, entities, { root: true });

    await dispatch('SET_FACILITY_ACTION', facility);
    storedFacilityId.set(facility.id);
  },

  GET_FACILITY_ACTION: async ({ dispatch, getters }) => {
    const response = await facilityRepository.get(getters.getUserFacilityID);

    await dispatch('SET_FACILITY_ACTION', response);
  },

  SET_PHONE_ACTION: async ({ dispatch, getters, commit }, { phoneId, replaceId }) => {
    const facility = await facilityRepository.selectPhone(
      getters.getUserFacilityID,
      phoneId,
      replaceId,
    );
    const { entities } = facilityMapper.normalizeSingle(facility);
    commit(MUTATION_ENTITIES_UPDATE, entities, { root: true });
    dispatch('SET_FACILITY_ACTION', facility);
  },

  SIGN_OUT_FROM_WORKSTATION_ACTION: async ({ dispatch }) => {
    await dispatch('SET_PHONE_ACTION', { phoneId: null, replaceId: null });
  },

  UPLOAD_PUBLIC_FILE_ACTION: ({ getters }, fileUrl) =>
    uploadMediaFileRepository.uploadPublicFile(getters.getUserFacilityID, fileUrl),

  UPLOAD_REGULAR_FILE_ACTION: ({ getters }, fileUrl) =>
    uploadMediaFileRepository.uploadRegularFile(getters.getUserFacilityID, fileUrl),

  UPLOAD_SENSITIVE_FILE_ACTION: ({ getters }, fileUrl) =>
    uploadMediaFileRepository.uploadSensitiveFile(getters.getUserFacilityID, fileUrl),
};

export const user = {
  state,
  mutations,
  getters,
  actions,
};
