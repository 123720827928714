import { singleton } from 'tsyringe';
import { ChannelConfiguration } from './ChannelConfiguration';

@singleton()
export class ConfigurationRepository {
  private configuration: ChannelConfiguration | null = null;

  get(): ChannelConfiguration | null {
    return this.configuration;
  }

  set(configuration: ChannelConfiguration): void {
    this.configuration = configuration;
  }

  clear(): void {
    this.configuration = null;
  }
}
