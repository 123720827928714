import { injectable } from 'tsyringe';
import { ConfigurationRepository } from './ConfigurationRepository';
import { DpEventBus } from '@/core/events/DpEventBus';
import { ChannelConfigurationChangedEvent } from './ChannelConfigurationChangedEvent';
import { RealTimeCommunicationConfigurationError } from './RealTimeCommunicationConfigurationError';
import { ChannelConfiguration } from './ChannelConfiguration';

@injectable()
export class ChannelConfigurationUpdater {
  constructor(
    private readonly configurationRepository: ConfigurationRepository,
    private readonly eventBus: DpEventBus,
  ) {}

  set(appKey: string, cluster: string, presenceChannelId: string, privateChannelId: string): void {
    const newConfiguration = ChannelConfiguration.make(
      appKey,
      cluster,
      presenceChannelId,
      privateChannelId,
    );
    const currentConfiguration = this.configurationRepository.get();

    if (currentConfiguration?.isEqual(newConfiguration)) {
      return;
    }

    this.configurationRepository.set(newConfiguration);
    this.eventBus.publish(new ChannelConfigurationChangedEvent('added', newConfiguration));
  }

  update(presenceChannelId: string, privateChannelId: string): void {
    const configuration = this.configurationRepository.get();
    this.assertConfiguration(configuration);

    const newConfiguration = ChannelConfiguration.make(
      configuration.appKey,
      configuration.cluster,
      presenceChannelId,
      privateChannelId,
    );

    if (configuration.isEqual(newConfiguration)) {
      return;
    }

    this.configurationRepository.set(newConfiguration);
    this.eventBus.publish(new ChannelConfigurationChangedEvent('changed', newConfiguration));
  }

  clear(): void {
    this.configurationRepository.clear();
    this.eventBus.publish(new ChannelConfigurationChangedEvent('removed', null));
  }

  private assertConfiguration(
    configuration: ChannelConfiguration | null,
  ): asserts configuration is ChannelConfiguration {
    if (!configuration) {
      throw new RealTimeCommunicationConfigurationError(
        "Pusher configuration weren't saved correctly",
      );
    }
  }
}
