import { inject, singleton } from 'tsyringe';
import { ClientManagerToken, UserNotifierToken } from '../di/tokens';
import type { UserNotifier } from './UserNotifier';
import { Orchestrator } from './Orchestrator';
import type { ClientManager } from './client/ClientManager';

@singleton()
export class ClientReconnectService {
  private static readonly maxDelayInSeconds = 600;

  private attemptNumber = 0;

  private timeout: NodeJS.Timeout | null = null;

  constructor(
    @inject(UserNotifierToken)
    private readonly userNotifier: UserNotifier,
    private readonly orchestrator: Orchestrator,
    @inject(ClientManagerToken)
    private readonly clientManager: ClientManager,
  ) {}

  initialize(): void {
    if (this.hasPendingTimeout) {
      return;
    }

    if (this.isConnected()) {
      this.finish();
      return;
    }

    this.userNotifier.clientUnauthorized();

    this.orchestrator.disconnect();

    this.reconnect();
  }

  finish(): void {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }

    this.attemptNumber = 0;
  }

  private isConnected(): boolean {
    return this.clientManager.state === 'connected';
  }

  private get hasPendingTimeout(): boolean {
    return this.timeout !== null;
  }

  private reconnect(): void {
    if (this.isConnected()) {
      this.finish();
      return;
    }

    const nextTimeout = this.getTimeout();

    this.timeout = setTimeout(async () => {
      await this.orchestrator.create();
      this.reconnect();
    }, nextTimeout);

    this.attemptNumber += 1;
  }

  private getTimeout(): number {
    const currentTimeout = 2 ** this.attemptNumber * 5 * 1000;

    return Math.min(currentTimeout, ClientReconnectService.maxDelayInSeconds * 1000);
  }
}
