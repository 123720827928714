import type { ClientManager } from '../../domain/client/ClientManager';
import type { ChannelConfiguration } from '../../domain/configuration/ChannelConfiguration';
import Pusher from 'pusher-js';
import { HttpAuthorizationRepository } from '../HttpAuthorizationRepository';
import { singleton } from 'tsyringe';
import { RealTimeCommunicationError } from '../../domain/RealTimeCommunicationError';
import { DpEventBus } from '@/core/events/DpEventBus';
import { ClientStateChanged } from '../../domain/events/ClientStateChanged';
import { ConnectionState } from '../../domain/connection/ConnectionState';

@singleton()
export class PusherClientManager implements ClientManager {
  private currentInstance: Pusher | null = null;

  constructor(
    private readonly authorizationRepository: HttpAuthorizationRepository,
    private readonly eventBus: DpEventBus,
  ) {}

  get state(): string {
    return this.currentInstance?.connection.state ?? '';
  }

  async make(configuration: ChannelConfiguration): Promise<Pusher> {
    this.destroy();

    if (this.requiresNewInstance(configuration)) {
      return this.getAndCacheClient(configuration);
    }

    return this.getClient(configuration);
  }

  update(configuration: ChannelConfiguration): Promise<Pusher> {
    if (!this.currentInstance) {
      throw new RealTimeCommunicationError('Cannot find Pusher instance to update');
    }

    for (const channel of this.currentInstance.allChannels()) {
      this.currentInstance.unsubscribe(channel.name);
      channel.unbind();
    }
    this.currentInstance.unbind('state_change');

    if (this.requiresNewInstance(configuration)) {
      return this.getAndCacheClient(configuration);
    }

    return this.getClient(configuration);
  }

  destroy(): void {
    if (!this.currentInstance) {
      return;
    }

    for (const channel of this.currentInstance.allChannels()) {
      this.currentInstance.unsubscribe(channel.name);
      channel.unbind();
    }
    this.currentInstance.unbind('state_change');
    this.currentInstance.disconnect();
  }

  getCurrent(): Pusher {
    if (!this.currentInstance) {
      throw new RealTimeCommunicationError('Cannot find Pusher instance');
    }

    return this.currentInstance;
  }

  private requiresNewInstance(configuration: ChannelConfiguration): boolean {
    if (!this.currentInstance) {
      return true;
    }

    return this.currentInstance.key !== configuration.appKey;
  }

  private async getAndCacheClient(configuration: ChannelConfiguration): Promise<Pusher> {
    this.currentInstance = this.createNew(configuration);

    return this.getClient(configuration);
  }

  private getClient(configuration: ChannelConfiguration): Promise<Pusher> {
    return new Promise((resolve, reject) => {
      const invalidStatus = ['unavailable', 'failed', 'disconnected'];

      if (!this.currentInstance) {
        throw new RealTimeCommunicationError('Cannot find Pusher instance');
      }

      this.currentInstance.connection.bind('state_change', ({ current, previous }) => {
        this.eventBus.publish(new ClientStateChanged(new ConnectionState(current, previous)));
      });

      this.currentInstance.connect();
      this.currentInstance.subscribe(configuration.presenceChannelId);
      this.currentInstance.subscribe(configuration.privateChannelId);

      const currentState = this.currentInstance.connection.state;
      if (currentState === 'connected') {
        resolve(this.currentInstance);
      } else if (invalidStatus.includes(currentState)) {
        reject(new RealTimeCommunicationError(`Cannot connect with pusher:  ${currentState}`));
      } else {
        const callback = ({ current }) => {
          if (current === 'connected') {
            resolve(this.currentInstance!);
            this.currentInstance?.connection.unbind('state_change', callback);
          } else if (invalidStatus.includes(current)) {
            reject(new RealTimeCommunicationError(`Cannot connect with Pusher: ${current}}`));
            this.currentInstance?.connection.unbind('state_change', callback);
          }
        };

        this.currentInstance.connection.bind('state_change', callback);
      }
    });
  }

  private createNew(configuration: ChannelConfiguration): Pusher {
    return new Pusher(configuration.appKey, {
      cluster: configuration.cluster,
      channelAuthorization: {
        transport: 'ajax',
        endpoint: '',
        customHandler: this.authorizationHandler.bind(this),
      },
    });
  }

  private async authorizationHandler({ socketId, channelName }, callback) {
    try {
      const { auth, channel_data } = await this.authorizationRepository.auth(channelName, socketId);
      callback(null, { auth, channel_data });
    } catch {
      callback(true, new Error(`Cannot authorize user for this channel: ${channelName}`));
    }
  }
}
