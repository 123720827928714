export class ChannelConfiguration {
  private presenceChannel: string;

  private privateChannel: string;

  private constructor(
    readonly appKey: string,
    readonly cluster: string,
    presenceChannelId: string,
    privateChannelId: string,
  ) {
    this.presenceChannel = presenceChannelId;
    this.privateChannel = privateChannelId;
  }

  get presenceChannelId(): string {
    return this.presenceChannel;
  }

  get privateChannelId(): string {
    return this.privateChannel;
  }

  updateChannels(presenceChannelId: string, privateChannelId: string): void {
    this.presenceChannel = presenceChannelId;
    this.privateChannel = privateChannelId;
  }

  isEqual(configuration: ChannelConfiguration): boolean {
    return (
      this.appKey === configuration.appKey &&
      this.cluster === configuration.cluster &&
      this.presenceChannelId === configuration.presenceChannelId &&
      this.privateChannelId === configuration.privateChannelId
    );
  }

  static make(
    appKey: string,
    cluster: string,
    presenceChannelId: string,
    privateChannelId: string,
  ): ChannelConfiguration {
    return new ChannelConfiguration(appKey, cluster, presenceChannelId, privateChannelId);
  }
}
