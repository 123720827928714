import type { DpModule } from '@/core/modules/DpModule';
import type { IDpProvider } from '@/core/di/DpProvider';
import { RootModule } from '@/common/root/RootModule';
import { TrackingEventModule } from '@/common/tracking-event';
import { AdaptersProvider } from './providers/AdaptersProvider';
import { ConnectionProvider } from './providers/ConnectionProvider';
import { ClientProvider } from './providers/ClientProvider';

export class RealTimeCommunicationModule implements DpModule {
  static readonly namespace = Symbol('RealTimeCommunication');

  static readonly requires = [RootModule, TrackingEventModule];

  providers(): IDpProvider[] {
    return [AdaptersProvider, ClientProvider, ConnectionProvider];
  }
}
